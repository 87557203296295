import { useProgramConfig } from '@drugfreesleep/app/(program)/course/program.gql';
import { useConfig } from '@drugfreesleep/config/useConfig';
import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import classNames from 'classnames';
import Image from 'next/image';
import care from 'public/assets/images/WellerLabs.png';
import { useEffect, useState } from 'react';
import { IoMdMoon } from 'react-icons/io';
import { SiAudiomack } from 'react-icons/si';

type ILogoProps = {
  className?: string;
};

const logos: any = {
  moon: () => <IoMdMoon />,
  care: () => <Image height={48} width={48} src={care} alt="" />,
  audio: () => <SiAudiomack />,
};

const Logo = ({ className }: ILogoProps) => {
  const slug = useProgramSlug();
  const config = useConfig();
  const { programConfig } = useProgramConfig(slug || '');
  const [name, setName] = useState<string | null>('');
  const [logo, setLogo] = useState<string | null>(null);

  const display = slug ? programConfig?.display : config?.display;
  useEffect(() => {
    if (display) {
      setName(display.name);
      setLogo(display.logo);
    }
  }, [config, programConfig]);
  return (
    <div
      className={classNames('mx-5 flex items-center font-semibold', className)}
    >
      <div className="mr-2">{logo && logos[logo]()}</div>
      <div>{name}</div>
    </div>
  );
};

export { Logo };
